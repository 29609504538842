import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Menu_buttons } from '../../components/menu_button/menu_button'
import { changeScreen } from '../../store/auth/auth-action'
import { useAppDispatch } from '../../store/hooks'
import { useTranslation } from 'react-i18next'
import UserMenuHeader from '../../components/user-menu-header'

import './styles.scss'
import { getCurrentLanguagePath } from '../../translation/getCurrentLanguagePath'

const MenuScreen = () => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const params = useParams()
    const { t } = useTranslation()
    const pathLang = getCurrentLanguagePath()

    useEffect(() => {
        if (params.brandName) {
            dispatch(changeScreen('Corporate Details'))
        }
    })

    const _closeModal = () => {              
        navigate(`/${pathLang}`)
    }

    const _nextScreen = (screen: string) => {
        dispatch(changeScreen(screen))
    }
    return (
        <div className="menu">
            <UserMenuHeader onClose={_closeModal} />

            <div className="menu__buttons-container">
                <Menu_buttons
                    text={t('my_pension')}
                    onClick={() => {
                        _nextScreen('My Impact')
                    }}
                />
                <Menu_buttons
                    text={t('transactions')}
                    onClick={() => {
                        _nextScreen('Transaction Screen')
                    }}
                />
                <Menu_buttons
                    text={t('following')}
                    onClick={() => {
                        _nextScreen('Following')
                    }}
                />
                <Menu_buttons
                    text={t('preferences')}
                    onClick={() => {
                        _nextScreen('Select Interest')
                    }}
                />
                <Menu_buttons
                    text={t('feedback')}
                    onClick={() => {
                        window.location.href = 'mailto:joost.schouten@zoho.com'
                    }}
                />
                <Menu_buttons
                    text={t('settings')}
                    onClick={() => {
                        _nextScreen('Settings')
                    }}
                />
            </div>
        </div>
    )
}

export default MenuScreen
