import Unfollow from '../../assets/images/svgs/unfollowPopUp.svg'
import './styles.scss'

type Prop = {
    id: string
    photo: {
        url: string
        id: string
    }
    logo: {
        url: string
        id: string
    }
    hideBackground: boolean
    hideLogo: boolean
    hideName: boolean
    name: string
    _openDetails: (id: string, name: string) => void
    _unfollow: (id: string) => void
}

const FollowingCard = ({
    id,
    photo,
    name,
    _openDetails,
    _unfollow,
    logo,
    hideBackground,
    hideLogo,
    hideName,
}: Prop) => {
    return (
        <div
            key={id}
            className="follow-brand"
            onClick={() => {
                _openDetails(id, name)
            }}
        >
            {!hideBackground && photo && photo.url && (
                <img className="follow-brand_img" src={photo.url} alt={name} />
            )}

            {(hideBackground || !photo) && !hideLogo && logo && logo.url && (
                <img className="follow-brand_logo" src={logo.url} alt={name} />
            )}

            {!hideName && !logo && (
                <p
                    style={{
                        color: hideBackground || !photo ? 'black' : 'white',
                    }}
                    className="follow-brand_name"
                >
                    {name}
                </p>
            )}

            <img
                onClick={(el) => {
                    el.stopPropagation()
                    _unfollow(id)
                }}
                src={Unfollow}
                className="follow-brand_unfollow"
            />
        </div>
    )
}

export default FollowingCard
